import React, { useState, useEffect } from 'react';
import * as brain from 'brain.js';
import Hero_Template from './hero/Hero_Template';
import convert from 'color-convert';
import Service_Template from './service/Service_Template';
import About_Template from './about/About_Template';
import Service_Gallery from './service/Service_Gallery';
import Navbar_2 from './navbar/Navbar_2';
import Navbar_3 from './navbar/Navbar_3';
import Testimonials_1 from './testimonials/Testimonials_1';
import Testimonials_2 from './testimonials/Testimonials_2';
import Contact_2 from './contact/Contact_2';
import Contact_3 from './contact/Contact_3';
import Contact_4 from './contact/Contact_4';
import Contact_5 from './contact/Contact_5';
import Contact_1 from './contact/Contact_1';
import Footer_1 from './footer/Footer_1';

const DesignGenerator = () => {
  const [inputColor, setInputColor] = useState('');
  const [generatedColor, setGeneratedColor] = useState('#FD7A4E');
  const [complementColor, setComplementColor] = useState('#4ED1FD');

  const serviceFeatures = [
    {
        image: require('./images/kopisenopati/kopi2.jpeg'),
        title: "FRESH Coffee",
        desc: "Our coffee is brewed with natural resources which will rejuvinate you after a healthy exercise."
    },
    {
      image: require('./images/kopisenopati/kopi3.jpg'),
      title: "DELICIOUS Food",
      desc: "Our Indonesian food is cooked with love and shared at a reasonable price."
    },
    {
      image: require('./images/kopisenopati/kopi4.jpg'),
      title: "STRONG Community",
      desc: "Our community is close and caring, and every day it continues to grow."
    },
    

    // Add more features as needed
  ];

  const aboutFeatures = [
    {
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
            </svg>
        ),
        title: "Analytics",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec congue, nisl eget molestie varius."
    },
    {
      icon: (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
          </svg>
      ),
      title: "Analytics",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec congue, nisl eget molestie varius."
    },
    {
      icon: (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
          </svg>
      ),
      title: "Analytics",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec congue, nisl eget molestie varius."
    },
    

    // Add more features as needed
  ];

  const images = [
    { url: require('./images/kopisenopati/kopi1.jpg'), description: '' },
    { url: require('./images/kopisenopati/kopi5.jpg'), description: '' },
    { url: require('./images/kopisenopati/kopi6.jpg'), description: '' },
    { url: require('./images/kopisenopati/kopi17.jpg'), description: '' },
    { url: require('./images/kopisenopati/kopi8.jpg'), description: '' },
    { url: require('./images/kopisenopati/kopi9.jpg'), description: '' },
    { url: require('./images/kopisenopati/kopi10.jpg'), description: '' },
    { url: require('./images/kopisenopati/kopi11.jpg'), description: '' },
    { url: require('./images/kopisenopati/kopi13.jpg'), description: '' },
    { url: require('./images/kopisenopati/kopi14.jpg'), description: '' },
    { url: require('./images/kopisenopati/kopi15.jpg'), description: '' },
    { url: require('./images/kopisenopati/kopi16.jpg'), description: '' },
    { url: require('./images/kopisenopati/kopi7.jpg'), description: '' },
    { url: require('./images/kopisenopati/kopi12.jpg'), description: '' },
    // Add more images as needed
  ];

  const testimonials = [
    {
      avatar: "https://randomuser.me/api/portraits/men/86.jpg",
      name: "Karim ahmed",
      title: "DevOp engineer",
      quote: "Lovely place to chill for a coffee. Friendly service. The food mostly are Indonesian dishes.  But the manual brew coffee was very good, and decent price for the expensive neighborhood, hard to believe."
    },
    {
        avatar: "https://api.uifaces.co/our-content/donated/xZ4wg2Xj.jpg",
        name: "Martin escobar",
        title: "Founder of meta",
        quote: "Nice and comfy place, not too crowded on weekdays."
    },
    {
        avatar: "https://randomuser.me/api/portraits/women/79.jpg",
        name: "Angela stian",
        title: "Product designer",
        quote: "Good coffee, great atmosphere"
    },
    
  ]

  const handleColorChange = (event) => {
    setInputColor(event.target.value);
  };

  const calculateComplementColor = (color) => {
    // Convert the input color to HSL
    const hsl = convert.hex.hsl(color);
  
    // Adjust the hue by 180 degrees to find the complementary hue
    const complementHue = (hsl[0] + 180) % 360;
  
    // Convert the complementary hue back to RGB
    const complementRgb = convert.hsl.hex([complementHue, hsl[1], hsl[2]]);
  
    return `#${complementRgb}`;
  };
  

  const generateHeroDesign = () => {
    setGeneratedColor(inputColor);
    setComplementColor(calculateComplementColor(inputColor));
  };

  return (
    <div className='overflow-x-hidden'>
      {/* <label htmlFor="colorInput">Enter a color:</label>
      <input 
        type="color" 
        id="colorInput" 
        value={inputColor} 
        onChange={handleColorChange} 
      />
      <button onClick={generateHeroDesign}>Generate Hero Design</button> */}
      <Navbar_3/>
      {generatedColor && (
        <Hero_Template 
          bgColor={'#FFFFFF'}
          textColor={generatedColor}
          title="Tabea Kopi Senopati"
          description="Enjoy our coffee and food after a fresh workout here in Senopati 😉"
          buttonLabel="Get Started"
          complementColor={complementColor}
          imageSrc="https://via.placeholder.com/400x200"
        />
        
      )}
      <br></br>
      <Testimonials_2 testimonials={testimonials} textColor={generatedColor}/>
      <br></br>
      {generatedColor && (
        <About_Template 
          title={'The Beginning'}
          content = {[
            "One day, I met up with my friends who were interested in cycling.",
            "They needed a place to meet up and discuss cycling locations..",
            "So we gathered here as a hub!",
            "My friends invited runners, triathletes, and other athletes to come here too.",
            "I started reaching out to artists and book readers as well.",
            "We all got along well and shared our stories.",
            "So, I decided to call this place 'Tabea Kopi'",
            "It means 'greetings' for all the people to share their stories over a cup of coffee."
          ]}
          signature={'~ Founder of Tabea Kopi'}
          bgColor={'#FFFFFF'}
          textColor={generatedColor}
        />
        
      )}
      {generatedColor && (
        <>
        <Service_Template 
          title={'Our Services'}
          services={serviceFeatures} 
          bgColor={generatedColor} 
          textColor={generatedColor} 
          buttonColor={complementColor}
        />
        
        <Service_Gallery 
          title={'Moments in Our Story'}
          description={'The people that come here never want to leave!!😁'}
          images={images} 
          bgColor={'#FFFFFF'}
          textColor={generatedColor}
        />
        </>
      )}
      <br></br>
      
      <Contact_1 />
      <Footer_1 />
    </div>
  );
};

export default DesignGenerator;
