import { faCamera, faPhone, faShop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState} from 'react'

function Contact_1() {
    
    const [body, setBody] = useState("");
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");


    const handleEmailClick = () => {
        const email = 'maxwellwijaya00@gmail.com';
        const subject = 'Contact Form Submission';
        const emailBody = 'Name: ' + name + '\n' + 'Phone Number: ' + phoneNumber + '\n' + 'Message: ' + '\n' + body 
    
        const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`;
    
        window.location.href = mailtoUrl;
    };
        
    return (
        
        <div className='w-full bg-white grid lg:grid-cols-2 grid-cols-1 mt-12 lg:px-4' id='contact'>
            <div className='h-full col-span-1'>
                <div className='h-full flex flex-col items-center justify-center'>
                    
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.20685300666!2d106.80834377486181!3d-6.236443161070719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f1bfd3323975%3A0xc7d5ddd862a119ec!2sTabea%20kopi!5e0!3m2!1sen!2sid!4v1713269648665!5m2!1sen!2sid" width={"100%"} height={500} style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                
                </div>
            
            </div>
            <div>
                <div className="lg:h-full h-60 border rounded shadow-md flex flex-col items-center justify-center px-4 gap-3 col-span-1">
                    <div className='text-3xl font-semibold font-playfair-display text-center'>Contact Us</div>
                        {/* <div>Ararasa BSD Lt 1 BSD, Tangerang</div> */}

                    <a className='hover:scale-110 transform transition-transform duration-200 ease-in-out bg-primary text-white px-4 py-2 rounded text-center w-full' href='https://wa.me/+6285692979536' target='_blank'><FontAwesomeIcon icon={faPhone}/> WHATSAPP</a>
                    <a className='hover:scale-110 transform transition-transform duration-200 ease-in-out bg-primary text-white px-4 py-2 rounded text-center w-full' href='https://www.instagram.com/tabeakopi_senopati?igsh=MTdrenZrOHdhYWQwMQ==' target='_blank'><FontAwesomeIcon icon={faCamera}/> INSTAGRAM</a>
                    {/* <a className='hover:scale-110 transform transition-transform duration-200 ease-in-out bg-primary text-white px-4 py-2 rounded text-center w-full' href='https://www.tiktok.com/@soulfit.fitness?_t=8kaWZfWvOqo&_r=1' target='_blank'><FontAwesomeIcon icon={faShop}/> TOKOPEDIA</a> */}

                        {/* <div className="form-control">
                            
                            <label className="label">
                                <span className="label-text">Name</span>
                            </label>
                            <input type="text" placeholder="Name..." className="input input-bordered" onChange={(e) => {setName(e.target.value)}}/>
                        
                        </div>
                        
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Phone Number</span>
                            </label>
                            <input type="text" placeholder="Phone Number..." className="input input-bordered" onChange={(e) => {setPhoneNumber(e.target.value)}}/>
                        </div>

                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Message</span>
                            </label>
                            <textarea type="text" placeholder="Message..." className="input input-bordered" onChange={(e) => {setBody(e.target.value)}}/>
                        </div>
                        
                        <div className="form-control mt-6">
                        <button className="btn bg-secondary text-white" onClick={handleEmailClick}>Send</button>
                        </div>
                     */}
                </div>
            </div>
            
            
        </div>
        
    )
}

export default Contact_1