import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faCoffee, faHeart, faStar } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Hero_Template = ({ bgColor, textColor, title, description, buttonLabel, complementColor, imageSrc }) => {
    const heroStyle = {
        backgroundColor: bgColor,
        color: textColor
    };

    const buttonStyle = {
        backgroundColor: complementColor,
    };

    const navigate = useNavigate();

    function scrollToSection(id, nextPageUrl) {
        
        // Change the page URL
        navigate(nextPageUrl);

        // Wait for a short time to ensure the page navigation has started
        
        const section = document.getElementById(id);
        const offset = 150;

        if (section) {
            const topPos = section.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
                top: topPos - offset,
                behavior: "smooth",
            });
        }
        
    }


  return (
    // <section className="h-screen grid" style={heroStyle}>
    //   <div className="hero-content grid lg:grid-cols-3 grid-cols-1 p-4">
    //     <div className='col-span-2  w-full text-center'>
    //       <h1 className='text-3xl font-bold'>{title}</h1>
    //       <p className='text-md'>{description}</p>
    //       <br></br>
    //       <a className={`px-4 py-2 text-white border rounded w-32`} style={buttonStyle} href=''>{buttonLabel}</a>
    //     </div>
    //     <div className='lg:col-span-1 lg:col-start-3'>
    //       <img src={imageSrc} alt="Hero" />
    //     </div>
    //   </div>
    // </section>
    <section
        className="relative flex flex-col items-center justify-center h-screen"
        style={heroStyle}
    >
        
        <motion.h1
            className="text-6xl font-bold mb-4 mt-12 text-center font-playfair-display"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            style={{ color: textColor }}
            whileHover={{scale: 1.1}}
        >
            {title}
        </motion.h1>
        <motion.h1
            className="text-lg mb-8 text-black text-center text-wrap w-[400px]"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            whileHover={{scale: 1.1}}
        >
            {description}
        </motion.h1>
        <motion.button
            className="text-white font-bold py-2 px-4 rounded-full shadow-lg"
            style={{background: textColor}}
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.3 }}
            whileHover={{ scale: 1.1 }}
            onClick={() => scrollToSection('about', '/home')}
        >
            Read More <FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon>
        </motion.button>
        <br></br>
        <motion.div
            className="heart-icon"
            style={{ text: textColor }}
            initial={{ scale: 1, y: 0 }} // Set initial y position
            animate={{ y: [0, -10, 0] }} // Include y position
            transition={{ repeat: Infinity, duration: 0.5, repeatDelay: 1 }}
        >
            <FontAwesomeIcon icon={faCoffee} />
        </motion.div>


        {/* <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="text-primary h-1/2 object-cover"
        
        >
            <img className='rounded-md' src={require('../images/kopisenopati/kopi1.jpg')}></img>
        </motion.div> */}
        
        
        
    </section>
  );
};

export default Hero_Template;
