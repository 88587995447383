import React from 'react'
import { useNavigate } from 'react-router-dom';

function OpeningQuestion({question, options, answer}) {
    const navigate = useNavigate();
    const checkAnswer = (choice) => {
        if(choice == answer){
            navigate({
                pathname: '/home',
            });
        }else{
            alert("Wow.. Maybe this website is not for you then :(");
            alert("But if you're still curious what the website looks like then pick 'Yess :)'");
        }
    };


    return (
        <div className='flex flex-col items-center justify-center h-screen'>
            <h1 className='text-6xl font-playfair-display text-center'>{question}</h1>
            <div className='flex gap-3 mt-4'>
            {options.map(option => {
                return(
                    <button onClick={(e) => checkAnswer(e.target.value)} value={option} className='w-32 px-4 py-2 bg-none rounded-full border hover:bg-primary hover:text-secondary duration-200'>{option}</button>
                )
                
            })}
            </div>
        </div>
    )
}

export default OpeningQuestion